var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_wrap"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_vm._v("政府侧场景")]),_c('div',{staticClass:"production_core_scene"}),_c('div',{staticClass:"production_card"},[_c('ProCard',{attrs:{"procardList":_vm.procardList}})],1),_c('p',[_vm._v("市场侧场景")]),_c('div',{staticClass:"production_card"},[_c('ProCard2',{attrs:{"procardList":_vm.procardList2}})],1),_vm._m(4),_c('div',{staticClass:"advantage_out_wrap"},[_c('AdvantageCard')],1),_vm._m(5),_c('imageScroll'),_c('div',{staticClass:"product_shows_wrap"},[_vm._m(6),_c('div',{staticClass:"with_center"},[_c('div',{staticClass:"bottom_img_wrap"},_vm._l((_vm.procardList3),function(item,index){return _c('div',{key:index,staticClass:"bottom_img_item"},[_c('h2',{staticClass:"hidden-xs-only"},[_vm._v(_vm._s(item.tit))]),_c('div',{staticClass:"bottom_img"},[_c('img',{attrs:{"src":item.src,"alt":""}}),_c('div',{staticClass:"img_desc"},[_vm._v(_vm._s(item.desc))])])])}),0)])]),_c('CallMe'),_c('MobileCallme')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_img"},[_c('div',{staticClass:"img_tit"},[_vm._v("产品大脑")]),_c('div',{staticClass:"img_desc"},[_vm._v("数字经济系统的核心，赋能政府侧、服务市场侧")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_title_wrap"},[_c('div',{staticClass:"production_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"production_title"},[_vm._v("什么是产业大脑")]),_c('div',{staticClass:"production_desc"},[_vm._v("一脑治产业，两端同赋能")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_box"},[_c('div',{staticClass:"production_item"},[_c('div',{staticClass:"item_left"},[_c('img',{attrs:{"src":require("../../assets/img/11feef877.png"),"alt":""}})]),_c('div',{staticClass:"item_right"},[_c('div',{staticClass:"right_tit"},[_vm._v("政府侧")]),_c('div',{staticClass:"right_desc"},[_vm._v(" 围绕产业发展全生命周期，基于产业经济发展科学规划，实现产业选择、产业治理、产业服务全流程数字化、智能化、精准化 ")])])]),_c('div',{staticClass:"production_item"},[_c('div',{staticClass:"item_left"},[_c('img',{attrs:{"src":require("../../assets/img/12.74947a2 (1).png"),"alt":""}})]),_c('div',{staticClass:"item_right"},[_c('div',{staticClass:"right_tit"},[_vm._v("市场侧")]),_c('div',{staticClass:"right_desc"},[_vm._v(" 基于数字化实现资源要素和企业服务需求的精准匹配，是激发企业活力、增添企业动力、加速经济高质量发展、实现高效协同的平台 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_title_wrap"},[_c('div',{staticClass:"production_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center",staticStyle:{"width":"40%"}},[_c('div',{staticClass:"production_title"},[_vm._v("产业大脑核心场景")]),_c('div',{staticClass:"production_desc"},[_vm._v(" 数智赋能、高效协同，助力实现产业高质量发展 ")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_title_wrap"},[_c('div',{staticClass:"production_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center",staticStyle:{"width":"40%"}},[_c('div',{staticClass:"production_title"},[_vm._v("四大优势，引领数字化改革")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_title_wrap"},[_c('div',{staticClass:"production_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center",staticStyle:{"width":"60%"}},[_c('div',{staticClass:"production_title"},[_vm._v(" 全面推进产业数字化改革，三支网产业大脑备受青睐 ")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_title_wrap"},[_c('div',{staticClass:"production_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"production_title"},[_vm._v("客户见证")]),_c('div',{staticClass:"production_desc"},[_vm._v(" 三支网产业大脑引领数据驱动产业发展新未来 ")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }